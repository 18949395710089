<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="hotlist"
        :gridOptions="gridOptions"
        :paginationPageSize="paginationPageSize" 
        :pagination="true"  
        :context="context"
        :enableCellTextSelection=true 
        id="customer-hotlist-grid"
        @first-data-rendered="onFirstDataRendered"
        @grid-size-changed="onGridSizeChanged"     
      >
      </ag-grid-vue>  
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import TravioAccessControl from '@/components/travio-pro/TravioAccessControl.vue';
import CellRendererHotlistActions from './cell-renderers/CellRendererHotlistActions.vue'
import { format } from 'date-fns'
export default {
  
  props: {
    applicationId: { required: true },
    customerId: { required: true },
    data: { required: true, type: Array },
  },
  components: {
    AgGridVue,
    CellRendererHotlistActions,
    TravioAccessControl,
    flatPickr
  },
  data () {
    return {
      hotlist: [],
      orderIdPrefix: '',
      gridOptions: null,
      gridApi: null,
      date:null,
      agentId:null,
      selectedDate:null,
      orderStatusId: null,
      searchtext:null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererHotlistActions
      },
      searchQuery: ''
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 25
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }   
  },
  created () {
    
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    this.hotlist = this.data
    
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'List ID', width:80, field: 'listId', filter: true, wrapText:true },
      { headerName: 'List Name', width:120, field: 'listName', filter: true, wrapText:true },
      { headerName: 'Items', width:80, field: 'items', sortable: true, wrapText:true },
      { 
        headerName: 'Created',
        field: 'createdDate',
        sortable: true,
        width:140,
        valueFormatter: (param) => param.value && format(new Date(param.value) , this.activeUserInfo.dateTimeFormat)
      },
       {
        headerName: "Actions",
        sortable: false,
        width:70,
        cellRendererFramework: Vue.extend(CellRendererHotlistActions)
      }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 30,
      sortable: true,
      resizable: false,
      autoHeight: true,
      suppressMenu: true
    }
    this.context = { componentParent: this }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

  },
  
  methods: {
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged(params) {
      var gridWidth = document.getElementById('customer-hotlist-grid').offsetWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.columnApi.getAllColumns();
      for (var i = 0; i < allColumns.length; i++) {
        var column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    },
    onViewHotlist(listId) {
      this.$router
        .push({
          name: "customer-hotlist-view",
          params: {
            applicationId: this.applicationId,
            customerId: this.customerId,
            hotlistId: listId,
          },
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>

<style>
  /* .ag-theme-material{
    width:100%;
  }  */
</style>
