import { Validator } from 'vee-validate';

const dict = {
  custom: {
    firstName: {
      required: 'First name is required',
      max: "Max length is 100 characters"
    },
    lastName:{
      required: 'Last name is required',
      max: "Max length is 100 characters"
    }, 
    email:{
      required: 'Email is required',
      email: "Email is invalid"
    },    
   
  }
};

export class CustomValidator {
  constructor() {}
  setupErrorMessages ()  {
    Validator.localize('en', dict);
  }
}
