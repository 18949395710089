<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon
      title="View Details"
      icon="EyeIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="getOrderDetails"
    />
  </div>
</template>

<script>

export default {
  methods: {
    getOrderDetails() {
     this.$router
        .push({
          name: "orderdetails-view",
          params: {
            applicationId: this.$route.params.applicationId,
            orderId: this.params.data.orderId,
          },
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
};
</script>
