<template>
  <div v-if="customerData">
    <!--#region Pricing Id field-->
    <div v-if="isEditForm" class="vx-row mb-6">
      <div class="vx-col w-full align-center">
        <span>Customer Id</span>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          name="id"
          v-model="form.customerId"
          :disabled="true"
        />
      </div>
    </div>
    <!--#endregion Pricing Id field-->

    <div class="vx-row">
      <!--#region Title field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Title:</span>
          </div>
          <div class="vx-col w-full">
            <v-select
              v-model="form.title"
              name="title"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :clearable="false"
              append-to-body
              :options="titleOptions"
            />
            <span class="text-danger text-sm">{{
              errors.first("title")
            }}</span>
          </div>
        </div>
      </div>
      <!--#endregion Currency field ends-->

      <!--#region FirstName -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full align-center">
            <span>First Name</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              name="firstName"
              v-model="form.firstName"
              v-validate="'required|max:100'"
              :disabled="readOnly"
            />
            <span class="text-danger text-sm">{{
              errors.first("firstName")
            }}</span>
          </div>
        </div>
      </div>
      <!--#endregion FirstName ends -->

      <!--#region LastName field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Last Name:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.lastName"
              name="lastName"
              :disabled="readOnly"
              v-validate="'required|max:100'"
            />
            <span class="text-danger text-sm">{{
              errors.first("lastName")
            }}</span>
          </div>
        </div>
      </div>
      <!--#endregion LastName field ends-->

      <!--#region Email field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Email:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.email"
              name="email"
              :disabled="readOnly"
                v-validate="{
                rules: { required: true, email:true, emailExist: true },
              }"
              
            />
            <span class="text-danger text-sm">{{
              errors.first("email")
            }}</span>
          </div>
        </div>
      </div>
      <!--#endregion Email field ends-->

      <!--#region int Ref field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Internal Reference:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.internalRef"
              name="internalRef"
              :disabled="readOnly"
              v-validate="''"
            />
            <span class="text-danger text-sm">{{
              errors.first("internalRef")
            }}</span>
          </div>
        </div>
      </div>
      <!--#endregion Language field ends-->

      <!--#region Language field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Default Language:</span>
          </div>
          <div class="vx-col w-full">
            <v-select
              v-model="form.languageCode"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="customerFormData.defaultLanguage"
            />
            <span class="text-danger text-sm">{{
              errors.first("languageCode")
            }}</span>
          </div>
        </div>
      </div>
      <!--#endregion Language field ends-->

      <!--#region LastName field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Company Id:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input class="w-full" v-model="form.companyId" disabled />
          </div>
        </div>
      </div>
      <!--#endregion LastName field ends-->

      <!--#region LastName field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Company Name:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input class="w-full" v-model="form.companyName" disabled />
          </div>
        </div>
      </div>
      <!--#endregion LastName field ends-->

      <!--#region Currency field -->

      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Default Currency:</span>
          </div>
          <div class="vx-col w-full">
            <v-select
              v-model="form.currencyCode"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="customerFormData.defaultCurrency"
            />
            <span class="text-danger text-sm">{{
              errors.first("currencyCode")
            }}</span>
          </div>
        </div>
      </div>
      <!--#endregion Currency field ends-->

      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <div class="flex items-center mt-8">
              <!-- <vs-checkbox class="" :disabled="readOnly"  v-model="form.acceptMarketing" /> -->
              <vs-switch class="mr-4" :disabled="readOnly"  v-model="form.acceptMarketing" />
              <span>Accept Marketing:</span>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <div class="flex items-center mt-8">
              <!-- <vs-checkbox class="" :disabled="readOnly"  v-model="form.acceptMarketing" /> -->
              <vs-switch class="mr-4" :disabled="readOnly"  v-model="form.isActive" />
              <span>Is Active:</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showMembershipFields" class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Membership Level:</span>
          </div>
          <div class="vx-col w-full">
            <v-select
              v-model="form.membershipId"
              :reduce="(x) => x.code"
              :disabled="readOnly"
              :options="customerFormData.membershipLevels"
            />
          </div>
        </div>
      </div>

      <div v-if="showMembershipFields" class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Membership Subscription ID:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.membershipSubscriptionId"
              name="Membership Subscription ID"
              :disabled="readOnly"
              v-validate="'max:150'"
            />
            <span class="text-danger text-sm">{{
              errors.first("Membership Subscription ID")
            }}</span>
          </div>
        </div>
      </div>
      
      <div v-if="showMembershipFields" class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Membership Expiry:</span>
          </div>
          <div class="vx-col w-full">
            <flat-pickr v-model="form.membershipExpiry" :config="{ dateFormat: 'Y-m-d' }" class="w-full" name="Membership Expiry" :disabled="readOnly" />
            <span class="text-danger text-sm">{{errors.first("Membership Expiry")}}</span>
          </div>
        </div>
      </div>

      
    </div>

    <vs-divider class="mb-5" />

    <!--Telephone Types-->
    <div class="vx-row">
      <div class="vx-col w-full mb-6">
        <feather-icon icon="PhoneIcon" class="mr-2" svgClasses="w-5 h-5" />
          <span class="leading-none font-medium">Telephone</span>
      </div>
    </div>

      <div class="vx-row">
      <template v-for="(phone, phoneIndex) in form.customerPhones">        
        <div class="vx-col w-full md:w-5/6"  :key="phoneIndex + '0'">
        <div class="vx-row">
        <!--#region Telephone Type field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Telephone Type:</span>
          </div>
          <div class="vx-col w-full">
            <v-select
                v-model="form.customerPhones[phoneIndex].telephoneType"
                :name="'telephoneType_' + phoneIndex"
                :reduce="(x) => x.code"
                :disabled="readOnly"
                :clearable="false"
                :options="telephoneType"
              />
              <span
                class="text-danger text-sm"
                v-if="errors.has('telephoneType_' + phoneIndex)"
                >{{
                  errors
                    .first("telephoneType_" + phoneIndex)
                    .replace(
                      "The telephoneType_" +
                        phoneIndex +
                        " field is required",
                      "Telephone Type is required"
                    )
                }}</span
              >
          </div>
        </div>
      </div>
      <!--#endregion Telephone Type ends-->

      <!--#region Prefix  field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Prefix:</span>
          </div>
          <div class="vx-col w-full">
            <div class="vx-col w-full">
                <v-select
              v-model="form.customerPhones[phoneIndex].telephonePrefix"
              :reduce="(x) => x.code"
              :name="'telephonePrefix_' + phoneIndex"
              :disabled="readOnly"
              :options="customerFormData.telePhonePrefix"
              v-validate="'required'"
            />
            <span
                class="text-danger text-sm"
                v-if="errors.has('telephonePrefix_' + phoneIndex)"
                >{{
                  errors
                    .first("telephonePrefix_" + phoneIndex)
                    .replace(
                      "The telephonePrefix_" +
                        phoneIndex +
                        " field is required",
                      "Telephone Prefix is required"
                    )
                }}</span>
          </div>
        </div>
      </div>
      </div>
      <!--#endregion Prefix  ends-->

      <!--#region Telephone No field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Telephone No:</span>
          </div>
          <div class="vx-col w-full">
              <vs-input
              class="w-full"
                type="tel"
                
                v-model="form.customerPhones[phoneIndex].telephoneNumber"
                :name="'telephoneNumber_' + phoneIndex"
                :disabled="readOnly"
                v-validate="{ phoneNumber:true, required:true }"
              />
            <span
                class="text-danger text-sm"
                v-if="errors.has('telephoneNumber_' + phoneIndex)"
                >{{
                  errors
                    .first("telephoneNumber_" + phoneIndex)
                    .replace(
                      "The telephoneNumber_" +
                        phoneIndex +
                        " field is required",
                      "Telephone Number is required"
                    )
                }}</span
              >
          </div>
        </div>
      </div>
      <!--#endregion addressLine1 ends-->

      <!--#region Is Primary field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Is Primary:</span>
          </div>
          <div class="vx-col w-full">
            <vs-switch
                class="mt-2"
                v-model="form.customerPhones[phoneIndex].telephoneIsPrimary"
                :name="'telephoneIsPrimary_' + phoneIndex"
                :disabled="readOnly"
                @input="resetPrimaryPhone($event, phoneIndex)"
              />
            
          </div>
        </div>
      </div>
      <!--#endregion Is Primary ends-->

      

        
        </div>
        </div>
        <div v-if = "!readOnly" class="vx-col w-full md:w-1/6 flex"  :key="phoneIndex + '1'">
        <div class="m-auto">              
              <vs-button
              @click="removeDynamicFields(phoneIndex, 'phones')"
              size="small"
              class="mt-8"
              type="border"
              color="danger"
              :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
              :disabled="readOnly"
              >Remove</vs-button
            >
          </div>
        </div>
        </template>
        <div v-if = "!readOnly" class="vx-col w-full mb-3">
        <vs-button
          @click="addDynamicFields('phones')"
          size="small"
          type="border"
          color="success"
          :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
          :disabled="readOnly"
          >Add Phone</vs-button>
      </div>
    </div>

    <vs-divider class="mb-5" />

    <!--Address Types-->

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 mb-6">
        <!-- Col Header -->
        <div class="flex items-end md:mt-0 mt-base">
          <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
          <span class="leading-none font-medium">Address</span>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <template v-for="(address, addressIndex) in form.customerAddresses">
      <vs-divider class="mb-5" v-if="addressIndex > 0" border-style="dotted" color="primary" :key="addressIndex + '00'"></vs-divider>
        <div class="vx-col w-full md:w-5/6"  :key="addressIndex + '0'">
        <div class="vx-row">
        <!--#region Address Name field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Address Name:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.customerAddresses[addressIndex].addressName"
              :name="'addressName_' + addressIndex"
              :disabled="readOnly"
              v-validate="'required|max:50'"
            />
              <span
                class="text-danger text-sm"
                v-if="errors.has('addressName_' + addressIndex)"
                >{{
                  errors
                    .first("addressName_" + addressIndex)
                    .replace(
                      "The addressName_" +
                        addressIndex +
                        " field is required",
                      "Address Name is required"
                    )
                }}</span
              >
          </div>
        </div>
      </div>
      <!--#endregion Address Name ends-->

      <!--#region Address Company field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Address Company:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.customerAddresses[addressIndex].addressCompany"
              :name="'addressCompany_' + addressIndex"
              :disabled="readOnly"
              v-validate="'max:50'"
            />
            <span
                class="text-danger text-sm"
                v-if="errors.has('addressCompany_' + addressIndex)"
                >{{
                  errors
                    .first("addressCompany_" + addressIndex)
                    .replace(
                      "The addressCompany_" +
                        addressIndex +
                        " field is required",
                      "Address is required"
                    )
                }}</span>
          </div>
        </div>
      </div>
      <!--#endregion Address Company ends-->

      <!--#region addressLine1 field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Address Line 1:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.customerAddresses[addressIndex].addressLine1"
              :name="'addressLine1_' + addressIndex"
              :disabled="readOnly"
              v-validate="'required|max:50'"
            />
            <span
                class="text-danger text-sm"
                v-if="errors.has('addressLine1_' + addressIndex)"
                >{{
                  errors
                    .first("addressLine1_" + addressIndex)
                    .replace(
                      "The addressLine1_" +
                        addressIndex +
                        " field is required",
                      "Address  Line 1 is required"
                    )
                }}</span>
          </div>
        </div>
      </div>
      <!--#endregion addressLine1 ends-->

      <!--#region addressLine2 field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Address Line 2:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.customerAddresses[addressIndex].addressLine2"
              :name="'addressLine2_' + addressIndex"
              :disabled="readOnly"
              v-validate="'max:50'"
            />
            <span
                class="text-danger text-sm"
                v-if="errors.has('addressLine2_' + addressIndex)"
                >{{
                  errors
                    .first("addressLine2_" + addressIndex)
                    .replace(
                      "The addressLine2_" +
                        addressIndex +
                        " field is required",
                      "Address is required"
                    )
                }}</span>
          </div>
        </div>
      </div>
      <!--#endregion addressLine2 ends-->

      <!--#region City field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>City:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.customerAddresses[addressIndex].addressCity"
              :name="'city_' + addressIndex"
              :disabled="readOnly"
              v-validate="'required|max:50'"
            />
            <span
                class="text-danger text-sm"
                v-if="errors.has('city_' + addressIndex)"
                >{{
                  errors
                    .first("city_" + addressIndex)
                    .replace(
                      "The city_" +
                        addressIndex +
                        " field is required",
                      "City is required"
                    )
                }}</span>
          </div>
        </div>
      </div>
      <!--#endregion city ends-->

      <!--#region State field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>State:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              v-model="form.customerAddresses[addressIndex].addressState"
              :name="'state_' + addressIndex"
              :disabled="readOnly"
              v-validate="'max:50'"
            />
            <span
                class="text-danger text-sm"
                v-if="errors.has('state_' + addressIndex)"
                >{{
                  errors
                    .first("state_" + addressIndex)
                    .replace(
                      "The state_" +
                        addressIndex +
                        " field is required",
                      "Address is required"
                    )
                }}</span>
          </div>
        </div>
      </div>
      <!--#endregion State ends-->

      <!--#region addressPostalCode field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Post Code:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              type="text"
              v-model="form.customerAddresses[addressIndex].addressPostalCode"
              :name="'postcode_' + addressIndex"
              :disabled="readOnly"
              v-validate="'required|max:50'"
            />
            <span
                class="text-danger text-sm"
                v-if="errors.has('postcode_' + addressIndex)"
                >{{
                  errors
                    .first("postcode_" + addressIndex)
                    .replace(
                      "The postcode_" +
                        addressIndex +
                        " field is required",
                      "Post Code is required"
                    )
                }}</span>
          </div>
        </div>
      </div>
      <!--#endregion addressPostalCode ends-->

      <!--#region Country field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Country:</span>
          </div>
          <div class="vx-col w-full">
            <v-select
              v-model="form.customerAddresses[addressIndex].addressCountryCode"
              :reduce="(x) => x.code"
              :name="'country_' + addressIndex"
              :disabled="readOnly"
              :options="customerFormData.countriesCode"
              v-validate="'required'"
            />
            <span
                class="text-danger text-sm"
                v-if="errors.has('country_' + addressIndex)"
                >{{
                  errors
                    .first("country_" + addressIndex)
                    .replace(
                      "The country_" +
                        addressIndex +
                        " field is required",
                      "Country is required"
                    )
                }}</span>
          </div>
        </div>
      </div>
      <!--#endregion Country ends-->
      <!--#region Is Address Primary field -->
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <span>Is Primary:</span>
          </div>
          <div class="vx-col w-full">
            <vs-switch
                class="mt-2"
                v-model="form.customerAddresses[addressIndex].addressIsPrimary"
                :name="'addressIsPrimary_' + addressIndex"
                :disabled="readOnly"
                @input="resetPrimaryAddress($event, addressIndex)"
              />
            
          </div>
        </div>
      </div>
      <!--#endregion Is Address Primary ends-->


        </div>
        </div>
        <div v-if = "!readOnly" class="vx-col w-full md:w-1/6 flex"  :key="addressIndex + '1'">
        <div class="m-auto">              
            <vs-button
              @click="removeDynamicFields(addressIndex, 'address')"
              size="small"
              class="mt-8"
              type="border"
              color="danger"
              :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
              :disabled="readOnly"
              >Remove</vs-button
            >
          </div>
        </div>
        </template>
        <div v-if = "!readOnly" class="vx-col w-full mb-3">
        <vs-button
          @click="addDynamicFields('address')"
          size="small"
          type="border"
          color="success"
          :class="readOnly ? 'opacity-50 cursor-not-allowed' : ''"
          :disabled="readOnly"
          >Add Address</vs-button
        >
      </div>        
    </div>

    <vs-divider class="mb-5" />

    <!-- Custom Fields -->
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 mb-6">
        <!-- Col Header -->
        <div class="flex items-end md:mt-0 mt-base">
          <!-- <feather-icon icon="MoreHorizontalIcon" class="mr-2" svgClasses="w-5 h-5" /> -->
          <span class="leading-none font-medium">Custom Fields</span>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div v-for="(field, index) in form.customFields" :key="index" class="vx-col w-full">
        <label>{{form.customFields[index].fieldName}}</label>
        <vs-textarea 
            counter="500"
            maxlength="500"
            rows="4"
            v-model="form.customFields[index].fieldValue"
            v-validate="'max:500'"
            :disabled="readOnly"
            class="w-full mt-2"/>
      </div>
      <div class="vx-col w-full mb-8" v-if="!form.customFields || form.customFields.length == 0">
        <div class="ml-4">No custom fields.</div> 
      </div>
    </div>
    <!-- Dates -->
    <div class="vx-row">
      <div v-if="isEditForm" class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full align-center">
            <span>Created Date:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              name="Created Date"
              :value="createdDate"
              :disabled="true"
            />
          </div>
        </div>
      </div>
      <div v-if="isEditForm" class="vx-col w-full md:w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col w-full align-center">
            <span>Updated Date:</span>
          </div>
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              name="Updated Date"
              :value="updatedDate"
              :disabled="true"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="lg:float-left mt-4">
      <span class="text-sm text-danger">*Required Field</span>
    </div>

    <div class="flex flex-wrap justify-end">
      <vs-button
        v-if="!readOnly"
        @click="handleCancel"
        color="danger"
        class="ml-auto mt-4 mr-4"
        >Cancel</vs-button
      >
      <vs-button v-if="!readOnly" @click="handleSubmit" class="mt-4"
        >Save</vs-button
      >
      <vs-button
        v-if="readOnly && !isSystemRole"
        @click="handleEditMode"
        class="ml-auto mt-4 mr-4"
        >Edit</vs-button
      >
      <vs-button v-if="readOnly" @click="handleCancel" class="mt-4"
        >Back</vs-button
      >
    </div>
  </div>
</template>

<script>
import { Form } from "@/core-modules/form-framework/Form.js";
import helper from "./customerHelper";
import customerStore from "./customerStore";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import _ from "lodash";
import { CustomValidator } from "./customerEditorValidator.js";
import { Validator } from 'vee-validate';
import axios from "@/axios.js"
import { format } from 'date-fns'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

const customvalidator = new CustomValidator();
customvalidator.setupErrorMessages();

export default {
  components: {
    flatPickr,
  },

  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: true },
    customerId: { default: 0 },
    customerData: { type: Object, required: true}
  },
  data() {
    return {
      helper: helper,
      form: new Form(_.cloneDeep(helper.customerModel)),
      languageOptions: [
        { label: "English", code: "en" },
        { label: "Spanish", code: "es" },
        { label: "French", code: "fr" },
        { label: "Russian", code: "ru" },
        { label: "German", code: "de" },
        { label: "Arabic", code: "ar" },
        { label: "Sanskrit", code: "sa" },
      ],
      currencyOptions: [
        { label: "USD", code: "USD" },
        { label: "GBP", code: "GBP" },
        { label: "EUR", code: "EUR" },
      ],
      telephoneType: [
        { label: "Generic", code: 1 },
        { label: "Mobile", code: 2 },
        { label: "Home", code: 3 },
        { label: "Office", code: 4 },
      ],
      statusType: [
        { label: "Active", code: 1 },
        { label: "Inactive", code: 0 }
      ],
      customFields: []
    };
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  computed: {
    customerFormData() {
      return this.$store.state.customerStore.formData;
    },
    langOptions() {
      return this.$store.state.lookups.languageOptions;
    },
    countryOptions() {
      return this.$store.state.lookups.countryOptions;
    },
      titleOptions () {
      return this.$store.state.lookups.titleOptions
    },
    preferedDateFormat() {
      return this.$store.state.accountStore.generalSettings.dateFormat;
    },
    pageTitle() {
      return this.readOnly
        ? "View Customer"
        : this.customerId > 0
        ? "Edit Customer"
        : "Add Customer";
    },
    showMembershipFields() {
      return this.appHasFeature('memberships')
    },
    isEditForm() {
      return this.customerId > 0 ? true : false;
    },
    isSystemRole() {
      return this.$route.query.isSystem;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    createdDate() {
      return format(new Date(this.form.createdDate) , this.activeUserInfo.dateTimeFormat)
    },
    updatedDate() {
      return format(new Date(this.form.updatedDate) , this.activeUserInfo.dateTimeFormat)
    }
  },
  methods: {
    handleSubmit() {
      this.$vs.loading();
      this.$validator.validateAll().then((result) => {
        if (result) {
          const action = this.isEditForm
            ? "customerStore/updateCustomer"
            : "customerStore/addCustomer";
          const payload = {
            data: this.form.data(),
            applicationId: this.$route.params.applicationId,
            customerId: this.customerId,
          };

          if (!this.isEditForm) {
            payload.data.applicationId = parseInt(this.$route.params.applicationId);
          }

          this.$store
            .dispatch(action, payload)
            .then((response) => {
              const successMsg = this.isEditForm ? "updated" : "added new";
              this.$_notifySuccess(`Successfully ${successMsg} Customer`);
              this.$router.push(
                `/applications/${this.$route.params.applicationId}/customers`
              );
            })
            .catch((error) =>
              this.$_notifyFailureByResponseData(error.response.data)
            )
            .finally(() => this.$vs.loading.close());
        } else {
          this.$vs.loading.close()
          this.$_notifyFailure(
            "Invalid form data. Please correct all form fields."
          );
        }
      });
    },
    handleCancel() {
      this.$router.push({
        name: "customer-list",
        params: { applicationId: this.$route.params.applicationId },
      });
    },
    handleEditMode() {
      this.$router.push({name: "customer-main-edit",
        params: {
          applicationId: this.$route.params.applicationId,
          customerId: this.customerId,
        },
      })
      .catch((error) => {
        console.error(error);
      });
     
    },
    resetPrimaryAddress(value, addressIndex) {
      // if isEnabledPrimary then reset all other addresses to not primary
      if(value) {
        this.form.customerAddresses
          .filter((x, index) => index != addressIndex)
          .forEach(x => x.addressIsPrimary = false)
      }
     
    },
    resetPrimaryPhone(value, phoneIndex) {

      if(value) {
        this.form.customerPhones
          .filter((x, index) => index != phoneIndex)
          .forEach(x => x.telephoneIsPrimary = false)
      }

    },
    addDynamicFields(fieldType) {
      if (fieldType === "phones") {
        const customerModel = _.cloneDeep(helper.customerPhone);
        if(this.form.customerPhones.length > 0){
          customerModel.telephoneIsPrimary = false;
        }
        this.form.customerPhones.push(customerModel);
      }
      if (fieldType === "address") {
         const addressModel = _.cloneDeep(helper.customerAddress);
        if(this.form.customerAddresses.length > 0){
          addressModel.addressIsPrimary = false;
        }
        this.form.customerAddresses.push(addressModel);
      }
      
    },
    removeDynamicFields(index, fieldType) {
      if (fieldType === "phones") {
        this.form.customerPhones.splice(index, 1);
        if(this.form.customerPhones.length > 0){
          this.form.customerPhones[0].telephoneIsPrimary = true;
        }
      }
      if (fieldType === "address") {
        this.form.customerAddresses.splice(index, 1);
        if(this.form.customerAddresses.length > 0){
          this.form.customerAddresses[0].addressIsPrimary = true;
        }
      }
      
    },
    async isEmailUnique() {
      try {        
        var customerId = 0;
        var email = this.form.email;
        if(this.customerId){
          customerId = this.customerId;
        }
        const response = await axios.get(
          `api/customersetting/${this.$route.params.applicationId}/isemailunique`,{ params: { customerId: customerId, email:email} }
        );       
        return response.data;
      } catch (err) {        
        console.log(err);
        return true;
      }
    }
  },
  created() {
    if (!customerStore.isRegistered) {
      this.$store.registerModule("customerStore", customerStore);
      customerStore.isRegistered = true;
    }

    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.$route.params.applicationId)) ) {
      this.$router.push('/error-404')
    }


   // custom validation 
    Validator.extend("emailExist", {
      getMessage: (field) => "This email has already been taken",
      validate: this.isEmailUnique
    });
    Validator.extend("phoneNumber", {
      getMessage: (field) => "Telephone Number should be numeric value",
      validate: (value) => {
        const routeCodeRegex = "^([0-9]+)$";
        return new RegExp(routeCodeRegex).test(value);
      },
    });
  },
  async mounted() {
    //#region fetch required data from api and store in state
    
    const resp = await this.$store.dispatch("customerStore/fetchFormData", this.$route.params.applicationId)
    if(!this.customerId) {
        this.form.customFields = resp.data.customFields     
    }
    

    if (this.customerId > 0) {
      Object.assign(this.form, this.customerData)
    }
  }
};
</script>

<style>
</style>
