<template>
  <travio-center-container pageTitle="Customer Details" gridWidth="2/3">
    <vx-card v-if="allDataLoaded">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model='currentTabIndex' class="tabs-shadow-none" id="profile-tabs" :key="0">

          <!-- Details -->
          <vs-tab label="Details">
            <div class="tab-general md:ml-0 md:mt-0 mt-0 ml-0">
              <customer-editor 
                :readOnly="readOnly"
                :customerId="customerId"
                :customerData="customerData"
              />
            </div>
          </vs-tab>

          <!-- Orders -->
          <vs-tab label="Orders">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
              <customer-order-list
                :applicationId="applicationId"
                :data="orders"
              />
            </div>
          </vs-tab>

          <!-- Hotlist -->
          <vs-tab label="Hotlists">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
              <customer-hotlist
                :applicationId="applicationId"
                :customerId="customerId"
                :data="hotlists"
              />
            </div>
          </vs-tab>
         
        </vs-tabs>
      </div>
    </vx-card>
    
  </travio-center-container>
</template>

<script>
import CustomerOrderList from './CustomerOrderList.vue'
import CustomerHotlist from './CustomerHotlist.vue'
import CustomerEditor from './CustomerEditor.vue'

export default {
  components: {
    CustomerOrderList,
    CustomerHotlist,
    CustomerEditor,
  },
  props: {
    applicationId: { required: true },
    customerId: { required: true },
    selectedTabIndex: { default: 0 },
  },
  data () {
    return {
      customerData: {},
      orders: [],
      hotlists: [],
      allDataLoaded: false,
      currentTabIndex: 0,
    }
  },
  computed: {
    readOnly() {
      if (this.$route.meta.readOnly) {
        return true
      }
      return false
    },
  },
  async mounted() {
    this.currentTabIndex = this.selectedTabIndex
    this.$vs.loading()
    try {
      const response = await this.$http.get(`api/customersetting/${this.applicationId}/customer/${this.customerId}`)
      const { orders, hotlists, ...responseRootData} = response.data;
      this.customerData = responseRootData
      this.orders = orders
      this.hotlists = hotlists
      this.allDataLoaded = true
    } catch (error) {
      this.$_notifyFailure(error)
    }
    this.$vs.loading.close();
    
  }
}
</script>

<style>

</style>