<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon
      title="View Details"
      icon="EyeIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="onView"
    />
  </div>
</template>

<script>

export default {
  methods: {
    getOrderDetails() {

    },
    onView () {
      this.params.context.componentParent.onViewHotlist(this.params.data.listId) 
    },
  },
};
</script>
